// src/components/Card.js

import React from "react";

const Seperator = () => {
  return (
    <div className="seperator">
      
    </div>
  );
};

export default Seperator;
